<template>
    <div class="SubjectUpdate">
        <div class="bodyClass">
            <van-cell-group>
                <van-field v-model="subjectName" required clearable label="主题" placeholder="必填" />
            </van-cell-group>
        </div>
        <div class="footClass" @click="updataSubject()">保存</div>
    </div>

</template>
<script>
import { mapGetters } from 'vuex'
import titleMixin from '@/mixin/title'
export default {
    name: 'SubjectUpdate',
    title: '修改主题',
    mixins: [titleMixin],
    data() {
        return {
            homeTitle: '修改主题',
            subjectName: '',
            mId: ''
        }
    },
    computed: {
        ...mapGetters('Mail', ['currentCtid'])
    },
    created() {

    },
    mounted() {
        this.pageInit()
    },
    methods: {
        pageInit() {
            if (this.$route.path == '/mail/SubjectUpdate') {
                let { subjectName, mId } = this.$route.params
                this.subjectName = subjectName
                this.mId = mId
            }
        },
        async   updataSubject() {
            let data = {
                type: 'subject',
                mIds: [this.mId],
                subject: this.subjectName
            }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                data.ctid = this.currentCtid
            } else {
                delete data.ctid
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.mails_mail
                let res = await this.axios.put(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success('修改成功')
                    this.$router.back()
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        }

    },
    watch: {
        $route() {
            this.pageInit()
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
